import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const WebsiteDesignSEOServicePage = () => {
  return (
    <PageLayout>
      <SEO
        title="IT Consulting - Custom Software Solutions - Websuasion"
        ogDescription="We provide IT consulting guidance so you understand your options for custom software solutions."
        image="/images/development/it-consulting-business-solutions.jpg"
      />
		  <TextBlock
		    textPadded
		    textLeft={
		      <div>
						<h1>IT Consulting and Custom Software Solutions</h1>
						<p>
							You aren't in the business of information technology. But, you realize your lack of IT Consulting is affecting your bottom line. We provide technology consulting guidance so you understand your options and opportunities. We learn all we can about your day-to-day work process. Learn how automation will reduce your administration cost. Put in place custom applications that make your staff more productive. Discover opportunities for new revenue streams. We love teaching you what's possible.
						</p>
						<p>
							We also love to work with your business coach and business consultant. Think of us as the technical side of your business strategy. They have helped you to understand what needs to change in your business. We will help you understand how to make it happen. With 20+ years of experience in business technology, we can put in place the right solution for you.
						</p>
						<FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
		      </div>
		    }
		    textRight={
		      <div>
					<img src="/images/development/it-consulting-business-solutions.jpg" className="rounded-image" alt="IT Consulting - Custom Software Solutions - Websuasion" />
		      </div>
		    }
		  />
			<PageWrapper color="charcole">
			  <TextBlock
			    textPadded
			    textLeft={
			      <div>
							<h2>Low Hanging Fruit of Efficiency</h2>
							<p>
								<a href="/development/digital-transformation-consulting-firm/">Improving efficiency</a> is usually the easiest way to improve business growth. What does your team spend most of its time doing? How much of what they do is repetitive? How much is redundant?
							</p>
							<h4>Do you like apples?</h4>
							<p>
								There are always places you can increase staff productivity across all your departments. Your sales team can spend less time on data entry and blind prospecting, and more time on warm leads. Field technicians can use process checklists and location check-ins. Your customer service department can detect customer issues earlier and respond faster. Your accounting department can gain clarity from other departments to improve invoicing. Our IT Consulting experts can find the best solution for your business.
							</p>
							<p>
								<em>How 'bout them apples?</em>
							</p>
			      </div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/low-hanging-fruit-efficiency.jpg" className="rounded-image" alt="IT Consulting Efficiency - Custom Software Solutions - Websuasion" />
			      </div>
			    }
			  />
			</PageWrapper>
			<PageWrapper color="white">
			  <TextBlock
			    textPadded
			    textLeft={
			      <div>
							<h2>Metrics To Boost Revenue</h2>
							<p>
								One place you are likely leaving money on the table is by not taking advantage of existing customer data. Your current customer-base can tell you a lot ... if you ask. When you collect transactional metrics, you get a deep view into your business. What those metrics reveal might surprise you.
							</p>
							<h3>IT Consulting To Learn Your Metric Opportunities</h3>
							<p>
								Do you know who your most valuable clients are? If you are only looking at the sales total, you don't. Which clients cost you the most in customer service and administration? Is the price you charge equal to the value you provide customers? How do you perform against industry trends? How do you measure up to competitors? These are all things business metrics can tell you.
							</p>
			      </div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/business-metrics-boost-sales.jpg" className="rounded-image" alt="IT Consulting Metrics - Custom Software Solutions - Websuasion" />
			      </div>
			    }
			  />
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<p>
								How about products and services? Which is your most profitable? Which has the highest potential demand? Which has the greatest ability to scale without adding administration cost? Once you can see hard data on activity in your business, the opportunities to improve jump out at you. Metrics will help you to make better and more agile decisions.
							</p>
							<p>
								Sit down with our IT Consulting specialists so they can show you all the possibilities.
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
			  <TextBlock
			    textPadded
			    textLeft={
			      <div>
							<h2>Compliance IT Consulting</h2>
							<p>
								Almost every industry has some compliance issues. If you take credit cards, you are responsible for some level of <a href="https://www.pcisecuritystandards.org/">PCI compliance</a>. If you have a website that collects data, you need terms of service and privacy policies in place to protect you. Interacting with customers overseas? Then you must follow the <a href="https://eugdpr.org/">General Data Protection Regulation</a> (GDPR) rules. In the medical field, you have <a href="https://www.hhs.gov/hipaa/for-professionals/security/laws-regulations/index.html">HIPAA compliance</a>. And on and on. Our IT consulting program navigates you through the requirements and checks all the boxes no matter the regulations.
				      </p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/compliance-hipaa-pci-osha.jpg" className="rounded-image" alt="IT Consulting Compliance - Custom Software Solutions - Websuasion" />
			      </div>
			    }
			  />
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Security IT Consulting</h2>
							<p>
								Security of your website and data transmission is mission critical. Know that your cloud infrastructure and staff policies are keeping your vulnerability low. We lock-down your servers to keep them protected. We set your systems up with <a href="https://en.wikipedia.org/wiki/Transport_Layer_Security">SSL Certificates</a>, encrypted <a href="https://en.wikipedia.org/wiki/HTTP_cookie">cookies</a>, and <a href="https://en.wikipedia.org/wiki/Multi-factor_authentication">two-factor authentication</a>.
							</p>
							<h2>Ongoing Support</h2>
							<p>
								We provide you with managed cloud hosting on <a href="https://www.ubuntu.com/">Ubuntu servers</a>. We install all security maintenance patches upon release. And we back up your data and digital assets daily. Uptime monitoring tells us the minute there is a problem with your web application. On the rare occasion that happens, we take immediate action. We make the deployment process easy. We provide QA servers for testing new features before they roll out to your customers.
	          	</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
			  <TextBlock
			    textPadded
			    textLeft={
			      <div>
							<h2>Solutions</h2>
							<p>
								Put machines and content to work for your people - staff and customers. Once we understand your unique niche, we can recommend the perfect solution. That might mean the installation and configuration of existing software. Simple. It could mean tying together existing solutions for system integration and metrics reporting. A little more involved. Or, are you so specialized that you need a custom system? That ... is our wheelhouse. Let's get started with the blueprint!
							</p>
							<p>
								We provide solutions for every industry. Don't see what you need below? Let us know!
							</p>
							<FormWrapper
			          modal
			          buttonProps={{
			            content: "Schedule An Introductory Call",
			            size: "tiny",
			            icon: "calendar alternate outline",
			          }}
			        >
			          <RequestMoreInfo />
			        </FormWrapper>
			      </div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/it-business-solutions.jpg" className="rounded-image" alt="IT Consulting - Custom Software Solutions - Websuasion" />
			      </div>
			    }
			  />
			</PageWrapper>
			<PageWrapper color="charcole">
			  <TextBlock
			    textPadded
			    textLeft={
			      <div>
							<h2>Payment Solutions</h2>
							<p>
								Through our partnerships, we have the ability to provide our clients with competitive rates for merchant accounts and offer loyalty programs and gift card options. The Websuasion Team has extensive knowledge integrating with our partner solutions and can provide payment system solutions to fit any business.
			      	</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/payment-solutions.jpg" className="rounded-image" alt="Payment Solutions - Custom Software Solutions - Websuasion" />
			      </div>
			    }
			  />
			</PageWrapper>
			<PageWrapper color="white">
			  <TextBlock
			    textPadded
			    textLeft={
			      <div>
							<h2>Lead Generation</h2>
							<p>
								We beging with robust keyword research to determine the search intent of your prospects. Then we built a marketing and lead funnel system that speaks to your ideal customer's pain points at each stage of the customer journey.
							</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/lead-generation.jpg" className="rounded-image" alt="Lead Generation - Custom Software Solutions - Websuasion" />
			      </div>
			    }
			  />
			</PageWrapper>
			<PageWrapper color="charcole">
			  <TextBlock
			    textPadded
			    textLeft={
			      <div>
							<h2>Pitching &amp; Closing</h2>
							<p>
								Your sales and service representatives out in the field need to be able to best present your product line to customers while quickly capturing as much customer profile data as possible. We design custom presentational sales and service apps for iOS and Android tablets which can create customer accounts, create orders, take payments, and communicate securely back to your cloud server.
							</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/pitching-closing.jpg" className="rounded-image" alt="Pitching and Closing - Custom Software Solutions - Websuasion" />
			      </div>
			    }
			  />
			</PageWrapper>
			<PageWrapper color="white">
			  <TextBlock
			    textPadded
			    textLeft={
			      <div>
							<h2>Inventory Control</h2>
							<p>
								Paperless inventory control can help your business to prevent loss and fulfillment delays while saving staff time. We offer a number of options including barcode and RFID which allow your staff to easily receive and ship orders no matter the location. All data is centralized in your own secure cloud server and can be monitored and administrated from your headquarters. Custom reporting is also available so that you are never unexpectedly under- or over-stocked.
							</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/inventory-control.jpg" className="rounded-image" alt="Inventory Control - Custom Software Solutions - Websuasion" />
			      </div>
			    }
			  />
			</PageWrapper>
			<PageWrapper color="charcole">
			  <TextBlock
			    textPadded
			    textLeft={
			      <div>
							<h2>Data Visualization &amp; Reporting</h2>
							<p>
								As your digital data collection grows, it becomes more difficult to understand what it means for your business. We have extensive experience in designing custom reporting algorithms and data exploration tools which can not only show you what is happening in your current business but can also reveal opportunities you may have never considered. We can provide beautiful, interactive charts and graphs that are easy to understand yet powerful in their depth.
							</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/data-visualization-reporting.jpg" className="rounded-image" alt="Data Visualization - Custom Software Solutions - Websuasion" />
			      </div>
			    }
			  />
			</PageWrapper>
			<PageWrapper color="white">
			  <TextBlock
			    textPadded
			    textLeft={
			      <div>
							<h2>Healthcare Portals</h2>
							<p>
								We have developed surgury registry portals for the healthcare device industry for over 10 years. These are customized solutions that solve device specific needs with surgical tracking and metrics for adverse events and trends.
							</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/healthcare.jpg" className="rounded-image" alt="Heathcare Portals - Custom Software Solutions - Websuasion" />
			      </div>
			    }
			  />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Schedule a meeting.</h4>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default WebsiteDesignSEOServicePage
